import React from "react";

import StyledHome from "./Home.styled";
import TemplatesNavbar from "./components/TemplatesNavbar";
import HomeTemplates from "./components/HomeTemplates";
import TemplatesRow from "@components/TemplatesRow";

import categories from "@data/categories";

interface HomeProps {
  data: { key: string; title: string; templates: any[] }[];
}

const Home: React.FC<HomeProps> = ({ data }) => {
  return (
    <StyledHome>
      <TemplatesNavbar links={categories} />
      <HomeTemplates>
        {Array.isArray(data) &&
          data.map(item => {
            return <TemplatesRow {...item} categoryId={item.key} />;
          })}
      </HomeTemplates>
    </StyledHome>
  );
};

export default Home;
