import React from "react";

const TemplatesNavbarBackground: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1920.394"
    height="236.437"
    viewBox="0 0 1920.394 236.437"
  >
    <g fill="#1a7ecf">
      <path
        d="M1914.517 533.614s-602.51-84.377-1919.791-37.276c-.644.016-.193-2.056-.193-2.056l.105-86.668 1301.947 19.53 617.932 9.725z"
        transform="translate(.167 -408.952) translate(5.454 99.013)"
      ></path>
      <path
        d="M1914.437 546.375c-138.254-27.142-428.357-48.347-563.836-58.557s-24.261-32.326-24.261-32.326h340.965l241.219.144 6.038 77.939v12.8z"
        opacity="0.195"
        transform="translate(.167 -408.952) translate(5.454 99.013)"
      ></path>
      <path
        d="M0 408.952c139.477 29.769 427.8 52.788 564.475 63.985s24.477 35.454 24.477 35.454H0v-99.44z"
        opacity="0.399"
        transform="translate(.167 -408.952)"
      ></path>
      <path
        d="M0 422.707s595.886 92.326 1911.67 40.667c10.5-.293 8.383 0 8.383 0v97.483L619.129 539.483l-437.239-7.184L0 529.311z"
        transform="translate(.167 -408.952)"
      ></path>
    </g>
  </svg>
);

export default TemplatesNavbarBackground;
