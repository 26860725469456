import styled from "styled-components";
import media from "@styles/media";

const TemplatesNavbarLink = styled.div`
  margin: 0 20px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.2rem;
  ${media.toTabletP`
    margin: 20px;
  `}
  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
`;

export default TemplatesNavbarLink;
