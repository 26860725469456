import styled from "styled-components";
import media from "@styles/media";

const TemplatesNavbarInner = styled.div`
  z-index: 100;
  height: 100%;
  margin: 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.toTabletP`
    background-color: #1a7ece;
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  `}
`;

export default TemplatesNavbarInner;
