import styled from "styled-components";
import media from "@styles/media";

const TemplatesNavbarBackground = styled.div`
  position: absolute;
  width: 100vw;
  height: 300px;
  ${media.toTabletP`
    display: none;
  `}
  svg {
    width: 100%;
    position: absolute;
    height: 300px;
    z-index: 1;
  }
`;

export default TemplatesNavbarBackground;
