import styled from "styled-components";
import media from "@styles/media";

const TemplatesRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 300px));
  justify-content: space-between;
  grid-gap: 50px;
  margin-top: 50px;
  ${media.toPhone`
    justify-content: center;
  `}
`;

export default TemplatesRowContainer;
