import React from "react";
import StyledTemplatesNavbar from "./TemplatesNavbar.styled";
import StyledTemplatesNavbarInner from "./components/TemplatesNavbarInner.styled";
import StyledTemplatesNavbarBackground from "./components/TemplatesNavbarBackground.styled";
import TemplatesNavbarLink from "./components/TemplatesNavbarLink.styled";

import TemplatesNavbarBackground from "@svg/TemplatesNavbarBackground";

import { Category } from "@data/categories";

interface TemplatesNavbarProps {
  links: Category[];
}

const TemplatesNavbar: React.FC<TemplatesNavbarProps> = ({
  links,
}) => {
  const goToSection = key => {
    const sectionByKey = document.querySelector(`#${key}`);

    sectionByKey &&
      sectionByKey.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  };
  return (
    <StyledTemplatesNavbar>
      <StyledTemplatesNavbarBackground>
        <TemplatesNavbarBackground />
      </StyledTemplatesNavbarBackground>
      <StyledTemplatesNavbarInner>
        {links.map(({ title, key }) => {
          return (
            <TemplatesNavbarLink
              key={key}
              onClick={() => goToSection(key)}
            >
              {title}
            </TemplatesNavbarLink>
          );
        })}
      </StyledTemplatesNavbarInner>
    </StyledTemplatesNavbar>
  );
};

export default TemplatesNavbar;
