import styled from "styled-components";
import media from "@styles/media";

const UnderlinedHeader = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  position: relative;
  color: rgb(26, 22, 22);
  display: inline;
  font-weight: 700;
  &::before {
    content: "";
    position: absolute;
    bottom: 8px;
    opacity: 0.7;
    left: 0;
    height: 15px;
    width: 100%;
    min-width: 100px;
    background: transparent
      linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
      no-repeat padding-box;
    z-index: -1;
    ${media.toPhone`
      height: 5px;
      bottom: 1px;
      min-width: 50px;
    `};
  }
  ${media.toPhone`
    font-size: 2rem;
  `};
  ${media.toPhoneSmall`
    font-size: 1.2rem;
  `};
`;

export default UnderlinedHeader;
