import styled from "styled-components";
import media from "@styles/media";

const HomeHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  a {
    text-decoration: none;
    ${media.toTabletP`
      width: 100%;  
    `}
  }
  button {
    &:first-child {
      margin-right: 15px;
    }
    ${media.toTabletP`
      width: 100%;
    `}
  }
  ${media.toTabletP`
    justify-content: space-between;
    flex-direction: column;
  `}
  ${media.toPhoneSmall`
    button {
      &:first-child {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  `}
`;

export default HomeHeaderButtons;
