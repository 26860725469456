export enum CategoryEnum {
  PORTFOLIO = "Portfolios",
  STORE = "eCommerce",
  DASHBOARD = "Admin&Dashboards",
  BLOG = "Blogs",
  LANDING = "Landing pages",
  KITS = "UI Kits",
}

export interface Category {
  key: string;
  title: string;
}

export default Object.keys(CategoryEnum).map(
  (item: string): Category => {
    return {
      key: item,
      title: (CategoryEnum as { [key: string]: string })[item],
    };
  },
);
