import styled from "styled-components";
import media from "@styles/media";

const HomeTemplates = styled.div`
  margin: 0 70px;
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  ${media.toTabletP`
    margin: 0 20px;
    align-items: center;
    margin-top: 100px;
  `}
`;

export default HomeTemplates;
