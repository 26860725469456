import React from "react";
import StyledTemplatesRow from "./TemplatesRow.styled";
import StyledTemplatesRowTitle from "./components/TemplatesRowTitle.styled";
import TemplatesRowContainer from "./components/TemplatesRowContainer.styled";

import UnderlinedHeader from "@components/UnderlinedHeader";
import Link from "@components/Link";
import TemplateBox from "@components/TemplateBox";
import { LinkTypes } from "@components/Link/types";

interface TemplatesRowProps {
  categoryId: string;
  title: string;
  templates: any[];
}

const TemplatesRow: React.FC<TemplatesRowProps> = ({
  categoryId,
  title,
  templates,
}) => {
  return (
    <StyledTemplatesRow id={categoryId}>
      <StyledTemplatesRowTitle>
        <UnderlinedHeader>{title}</UnderlinedHeader>
        <Link
          to={`/templates/search/${categoryId.toLowerCase()}`}
          title={title}
          type={LinkTypes.Secondary}
        >
          View All
        </Link>
      </StyledTemplatesRowTitle>
      <TemplatesRowContainer>
        {templates.slice(0, 8).map(item => (
          <TemplateBox {...item} />
        ))}
      </TemplatesRowContainer>
    </StyledTemplatesRow>
  );
};

export default TemplatesRow;
